import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './questionList.module.sass';
import getSkin from './skin.js';
import MCQ from '../mcq';
import MAQ from '../maq';
import StarComponent from 'svgComponents/starComponent/';
import applyLabel from 'utils/LabelProvider';
import ResetIcon from 'svgComponents/resetIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class QuestionList extends React.Component {

	onResetAnswerClick = (question) => {
		let { setQuizQuestionAnswer } = this.props;
		switch (question.get('question_type')) {
			case "multiple_choice_question":
				console.log("mcq reset")
				setQuizQuestionAnswer(question.get('id'), null)
				break;
			case "multiple_answers_question":
				console.log("maq reset")
				setQuizQuestionAnswer(question.get('id'), [])
				break;
			case "true_false_question":
				console.log("mcq reset")
				setQuizQuestionAnswer(question.get('id'), null)
				break;
		}

	}

	getQuestionsContent = () => {
		let { questions, setQuizQuestionAnswer, flagQuestion, getLabelWithDefaults } = this.props;
		let questionsContent = [];
		let styles = getSkin(this.props.skinGuide);

		if (questions && questions.size > 0) {
			questionsContent = questions.map((question, index) => {
				return (
					<div key={`question_${question.get('id')}`} styleName="question-data-cnt">
						<div styleName="question-number-row">
							<div styleName="flag-and-number-cnt">
								<div
									styleName="flag-question-cnt"
									title={this.props.getLabelWithDefaults('quiz_flag_this_question_label', 'Flag this questions')}
									onClick={() => flagQuestion(question)}
								>
									<StarComponent
										id={`question_flag_${question.get('id')}`}
										fill="#d44980"
										strokeColor="#d44980"
										starWidthPerc={question.get('flagged') ? 100 : 0}
										doNotAnimate={true}
									/>
								</div>
								<div className={css(styles.questionNumber)}>
									{this.props.getLabelWithDefaults('question_number_label', `Question ${index + 1}`, { number: index + 1 })}
								</div>
							</div>
							<div className={css(styles.questionPoints)} style={{ display: 'flex', alignItems: 'center' }}>
								<div
									styleName="reset-icon-cnt"
									title={getLabelWithDefaults('quiz_reset_answer_label', 'Reset Answer')}
									onClick={() => this.onResetAnswerClick(question)}
								>
									<ResetIcon />
								</div>
								{getLabelWithDefaults(
									'quiz_questions_points_possible_label',
									`${question.get('points_possible')} PTS`,
									{ points: question.get('points_possible') }
								)}
							</div>
						</div>
						{this.getQuestionsTemplate(question, setQuizQuestionAnswer)}
					</div>
				)
			})
		}
		return questionsContent
	}

	getQuestionsTemplate = (question, setQuizQuestionAnswer) => {
		let { selectedAnswers, currentQuestion } = this.props;
		let questionAnswers = question.get('answer');
		let currentAnswerSelected = selectedAnswers.find(answer => answer.get('id') == question.get('id'));
		if (currentAnswerSelected) {
			questionAnswers = currentAnswerSelected.get('answer')
		}
		let paramsForQuestionTemplate = {
			setQuizQuestionAnswer: setQuizQuestionAnswer,
			key: `question_${question.get('id')}`,
			question: question,
			questionAnswers: questionAnswers,
			currentQuestion: currentQuestion
		}
		switch (question.get('question_type')) {
			case "multiple_choice_question":
				return <MCQ {...paramsForQuestionTemplate} />
			case "multiple_answers_question":
				return <MAQ {...paramsForQuestionTemplate} />
			case "true_false_question":
				return <MCQ {...paramsForQuestionTemplate} />
			default:
				return <div key={`question_${question.get('id')}`}>Some default template</div>
		}
	}

	render() {
		let styles = getSkin(this.props.skinGuide);

		return (
			<div>
				{this.getQuestionsContent()}
			</div>
		)
	}
}

export default QuestionList;