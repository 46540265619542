import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './journeyCard.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent, checkIfValueIsTrue } from 'utils/utilFunctions';
import Calendar from 'svgComponents/calendarSvg';
import Clock from 'svgComponents/clock';
import SessionBellIcon from 'svgComponents/sessionBellIcon';
import SessionActiveIcon from 'svgComponents/sessionActiveIcon';

import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import LockIcon from 'svgComponents/lockIcon';
import LockTooltip from 'commonComponents/lockTooltip';
import { getFormattedSessionDate, WEB_SESSION_END_TIMEOUT_BUFFER } from 'utils/webSessions';

import TickMarkWithGreenFill from 'svgComponents/tickMarkWithGreenFill';
import { shouldAllowSelfEnrollmentInBatch } from "utils/batchUtils.js";

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyCard extends Component {

	setActiveTimeout = null;
	setFinishedTimeout = null;

	constructor(props){
		super(props);
		this.state = {};
		if(props.webSessionDetails && props.webSessionDetails.session){
			const { hasActiveSession } = props.webSessionDetails;
			this.state = {
				isSessionActive: hasActiveSession
			}
		}
	}

	componentDidMount(){
		if(this.props.webSessionDetails && this.props.webSessionDetails.session){
			const { hasActiveSession, hasUpcomingSessionToday, session } = this.props.webSessionDetails;
			const twoMinutesInMilliseconds = 120000;
			if(!hasActiveSession || hasUpcomingSessionToday){
				const timeToGoForTheSession = Math.abs(new Date(session.get('startDate')) - new Date());
				console.log('debugws JourneyCard timeToGoForTheSession', timeToGoForTheSession);
				if(timeToGoForTheSession <= twoMinutesInMilliseconds){ //if less than or equal to 2 mins to go
					console.log('debugws JourneyCard isSessionActive', true);
					this.setState({
						isSessionActive: true
					});
				} else {
					console.log('debugws JourneyCard setTimeout', timeToGoForTheSession - twoMinutesInMilliseconds);
					this.setActiveTimeout = window.setTimeout(() => {
						console.log('debugws JourneyCard setTimeout executing');
						this.setState({
							isSessionActive: true
						});
					}, timeToGoForTheSession - twoMinutesInMilliseconds);
				}
			}
			const timeToGoForTheSessionEnd = Math.abs(new Date(session.get('endDate')) - new Date());
			console.log('debugws: timeToGoForTheSessionEnd', timeToGoForTheSessionEnd - WEB_SESSION_END_TIMEOUT_BUFFER);
			this.setFinishedTimeout = window.setTimeout(() => {
				console.log('debugws JourneyCard session finished timeout executing');
				this.setState({
					isSessionActive: false
				});
				this.props.onSessionFinish((new Date()).getTime());
			}, timeToGoForTheSessionEnd  - WEB_SESSION_END_TIMEOUT_BUFFER);
		}
	}

	componentWillUnmount(){
		window.clearTimeout(this.setActiveTimeout);
		window.clearTimeout(this.setFinishedTimeout);
	}
	
	sendSegmentData = () => {
		const { batchDetails } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			selectedBatchId: batchDetails.get('id'),
			selectedJourneyName: batchDetails.getIn(['journey', 'name']),
			selectedJourneyId: batchDetails.getIn(['journey', 'id'])
		}

		window.analytics.track(
			`Go to Journey Clicked - ${batchDetails.getIn(['journey', 'name'])}`,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	goToJourney = () => {
		/* if (this.props.setBatchSwitching) {
			this.props.setBatchSwitching(true);
		} */
		const { batchDetails } = this.props;
		this.sendSegmentData();

		if (batchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true') {
			let route = routes.get('BATCH_ITEM')
			let params = [{ 'BATCH_ID': batchDetails.get('id') }];
			params.push({ 'ITEM_ID': batchDetails.getIn(['journey', 'phaseItemId']) });
			updateRoute({ route, params });
			/* const destinationBatchId = this.props.batchDetails.get('id');
			window.location.href = `${window.location.origin}?ts=${Date.now()}/#/batch/${destinationBatchId}/item/${batchDetails.getIn(['journey', 'phaseItemId'])}` */
		} else {

			/* const destinationBatchId = this.props.batchDetails.get('id');
			window.location.href = `${window.location.origin}?ts=${Date.now()}/#/batch/${destinationBatchId}` */

			/* if (this.props.setBatchSwitching) {
				if (
					this.props.activeBatchId !== -1
					&& this.props.activeBatchId !== this.props.batchDetails.get('id')
				) {
					this.props.setBatchSwitching(true);
				} else {
					this.props.setBatchSwitching(false);
				}
			} */
			const route = routes.get('BATCH');
			const params = [{ 'BATCH_ID': this.props.batchDetails.get('id') }];
			updateRoute({ route, params });
		}
	}

	getButtonLabel = (workflowState) => {
		const { getLabel } = this.props;
		switch (workflowState) {
			case 'ACCEPTED':
				return getLabel('continue_learning_label');
			case 'INVITED':
				return getLabel('start_learning_label');
			default:
				return getLabel('start_learning_label');
		}
	}

	openJourneyPopup = (journeyInstanceId, journeyId, journeyName, batchId = null, isRenderedInsideMyCourses = false) => {
		const { setJourneyIntroductionPopup, userOrganization } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			journeyInstanceId: journeyInstanceId,
			journeyId: journeyId,
			journeyName: journeyName
		}
		window.analytics.track(
			'View Journey Details Clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)

		console.log("corp org ====> ", this.props)
		const isExpressInterestEnabled = !isRenderedInsideMyCourses && checkIfValueIsTrue(userOrganization.getIn(['userOrganization', 'platformSettings', 'expressInterestsEnabled']));

		if(isExpressInterestEnabled) {
			setJourneyIntroductionPopup(journeyId)
		} else {
			setJourneyIntroductionPopup(journeyInstanceId, batchId, isRenderedInsideMyCourses);
		}
	}

	handleOnClickAboutRoute = () => {
		const route = `${routes.get('BATCH')}/about`;
		const params = [{ 'BATCH_ID': this.props.batchDetails.get('id') }];

		updateRoute({ route, params });
	};

	onClickAboutJourney = () => {
		const { batchDetails } = this.props;

    batchDetails.get("journey").get("settings").get("isSimulationJourney") ===
    "true"
      ? this.openJourneyPopup(
          batchDetails.get("journeyInstanceId"),
          batchDetails.getIn(["journey", "id"]),
          batchDetails.getIn(["journey", "name"]),
          batchDetails.get("id"),
					true
        )
      : this.handleOnClickAboutRoute();
  };

	getEnrollInterestedRequestLabel = () => {
		const { userOrganization, batchDetails, getLabel } = this.props;
		let label = "";

		if(checkIfValueIsTrue(userOrganization.getIn(['userOrganization', 'platformSettings', 'expressInterestsEnabled']))) {
			if(batchDetails.getIn(["journey", "isInterested"])) {
				label = getLabel("interested_label");
			} else {
				label = getLabel('i_m_interested_label');
			}
		} else {
			if(shouldAllowSelfEnrollmentInBatch(batchDetails.getIn(["journey", "format", "key"]) === "SELF_PACED", userOrganization)) {
				label = getLabel("enrol_label");
			} else {
				label = getLabel("request_access_label");
			}
		}

		return label;
	}

	onClickOfEnrollInterestedRequestBtn = () => {
		const { userOrganization, batchDetails, showJourneyInterest, removeJourneyInterest } = this.props;

		const isInterestEnabled = checkIfValueIsTrue(userOrganization.getIn(['userOrganization', 'platformSettings', 'expressInterestsEnabled']));
		const isStatusInterested = isInterestEnabled && batchDetails.getIn(["journey", "isInterested"]);

		if(isInterestEnabled) {
			console.log("corp showInterest payload ====> ", batchDetails.toJS())
			const journeyId = batchDetails.getIn(["journey", "id"]);
			const orgId = userOrganization.getIn(['userOrganization', "id"]);

			if(!isStatusInterested) {
				showJourneyInterest({ journeyIds: [journeyId], orgId }, "");
			} else {
				removeJourneyInterest({ journeyIds: [journeyId], orgId }, "");
			}
		} else {
			this.openJourneyPopup(
				batchDetails.get('id'),
				batchDetails.getIn(['journey', 'id']),
				batchDetails.getIn(['journey', 'name'])
			);
		}
	}

	getAction = (myStyles, isBatchLockedBecauseOfPrereq) => {
		const {
			isActive,
			isCompleted,
			isAvailable,
			isRequested,
			isExpired,
			batchDetails,
			userBatch,
			isHomeScreenJourneyCard,
			getLabel,
			userOrganization,
			isCorpDeckJourney
		} = this.props;
		if (isHomeScreenJourneyCard) {
			return (
				<div onClick={this.goToJourney}
					className={css(myStyles.aboutJourneyLabel)}>
					{this.getButtonLabel(userBatch.get('workflowState'))}
				</div>
			)
		}
		if (isAvailable) {
			const isStatusInterested = isCorpDeckJourney && batchDetails.getIn(["journey", "isInterested"]);
			const isBDPassportOrg = userOrganization.getIn(["userOrganization", "homePageStyle", "name"]) === "BdPassport";

			return [
				!isBDPassportOrg && <div
					key="about-journey"
					className={css(myStyles.aboutJourneyLabel)}
					styleName='journey-action-btn'
					onClick={() => {
					this.openJourneyPopup(
					batchDetails.get('id'),
					batchDetails.getIn(['journey', 'id']),
					batchDetails.getIn(['journey', 'name']
					))}}>
					{getLabel('about_label')}
				</div>,
				<div onClick={this.onClickOfEnrollInterestedRequestBtn}
					styleName='journey-action-btn'
					className={css(myStyles.aboutJourneyLabel)} style={isStatusInterested ? { background: "rgba(108, 188, 20, 0.2)", color: "#6CBC14"} : {}}>
					{isStatusInterested && <div styleName='interested-tick'><TickMarkWithGreenFill /></div>}
					{
						this.getEnrollInterestedRequestLabel()
					}
				</div>
			]
		}

		if(isActive && isBatchLockedBecauseOfPrereq){
			return <div
				key="about-journey"
				className={css(myStyles.aboutJourneyLabel)}
				onClick={() => this.openJourneyPopup(
					batchDetails.get('journeyInstanceId'),
					batchDetails.getIn(['journey', 'id']),
					batchDetails.getIn(['journey', 'name']),
					batchDetails.get('id'),
					true
				)}>
				{getLabel('about_journey_label')}
			</div>;
		}
 
		if (isActive || isCompleted || isExpired) {
			return (
				[
					<div key="start-journey" className={css(myStyles.aboutJourneyLabel)} onClick={this.goToJourney}>
						{
							isCompleted || isExpired ?
								getLabel('view_journey_label')
								:
								this.getButtonLabel(userBatch.get('workflowState'))
						}
					</div>,
					<div
						key="about-journey"
						className={css(myStyles.aboutJourneyLabel)}
						onClick={this.onClickAboutJourney}>
						{getLabel('about_label')}
					</div>
				]
			)
		} else if (isRequested) {
			return (
				<div
					className={css(myStyles.aboutJourneyLabel)}
					onClick={() => this.openJourneyPopup(
						batchDetails.get('id'),
						batchDetails.getIn(['journey', 'id']),
						batchDetails.getIn(['journey', 'name']),
						null,
						isRequested
						)}>
					{getLabel('about_journey_label')}
				</div>
			)
		} else if (userBatch.get('durationStatus') === 'UPCOMING') {
			return (
				<div
					className={css(myStyles.aboutJourneyLabel)}
					onClick={this.onClickAboutJourney}>
					{getLabel('about_journey_label')}
				</div>
			)
		} else {
			return (
				<div
					className={css(myStyles.aboutJourneyLabel)}
					onClick={() => this.openJourneyPopup(
						batchDetails.get('journeyInstanceId'),
						batchDetails.getIn(['journey', 'id']),
						batchDetails.getIn(['journey', 'name']
						))}>
					{getLabel('about_journey_label')}
				</div>
			)
		}
	}

	getProgressComponent = (myStyles) => {
		const {
			getLabel,
			userBatch
		} = this.props;

		const percentage = userBatch.get('percentageCompletion') ? Math.round(userBatch.get('percentageCompletion') * 100) / 100 : 0;

		return (
			<div key="progress-cnt" styleName="journey-progress-cnt">
				<div className={css(myStyles.journeyCompletion)}>
					{getLabel('journey_completion_label').replace(
						'COMPLETION_PERCENTAGE',
						percentage
					)}
				</div>
				<div styleName="progress-bar">
					<div className={css(myStyles.completedBg)} styleName="completed-progress" style={{ width: `${userBatch.get('percentageCompletion') || 0}%` }}></div>
				</div>
			</div>
		)
	}

	getDurationDetails = (myStyles) => {
		const {
			batchDetails,
			isHomeScreenJourneyCard
		} = this.props;
		if (isHomeScreenJourneyCard)
			return this.getProgressComponent(myStyles);
		else
			return <div key="other-details" styleName="other-details-cnt">
				{checkIfPresent(batchDetails.getIn(['journey', 'duration'])) &&
					<div key="duration-cnt" styleName="duration-cnt">
						<div styleName="icon">
							<Calendar />
						</div>
						<div className={css(myStyles.otherDetailsKey)}>
							{`${batchDetails.getIn(['journey', 'durationLabel'])}:`}
						</div>
						<div className={css(myStyles.otherDetailsValue)} title={batchDetails.getIn(['journey', 'duration'])}>
							{`${batchDetails.getIn(['journey', 'duration'])}`}
						</div>
					</div>}
				{checkIfPresent(batchDetails.getIn(['journey', 'effort'])) &&
					<div key="study-details-cnt" styleName="study-details-cnt">
						<div styleName="icon">
							<Clock />
						</div>
						<div className={css(myStyles.otherDetailsKey)}>
							{`${batchDetails.getIn(['journey', 'effortsNeededLabel'])}:`}
						</div>
						<div className={css(myStyles.otherDetailsValue)} title={batchDetails.getIn(['journey', 'effort'])}>
							{`${batchDetails.getIn(['journey', 'effort'])}`}
						</div>
					</div>}
			</div>;
			
	}

	renderJoinSessionButton = (webSession, myStyles) => {
		return <div styleName='join-session-button' className={css(myStyles.joinSession)}>
			<div styleName="websession-active-icon-cnt"><SessionActiveIcon/></div>
			<div className={css(myStyles.joinText)}>JOIN</div>
		</div>;
	}

	openWebSession = (webSession) => {
		window.open(webSession.get('link'));
	}

	renderWebSessionDetails = (webSessionDetails, myStyles) => {
		if(this.state.isSessionActive){
			return <div styleName="websession-details-cnt">
				<div styleName="active-websession-text" className={css(myStyles.activeSessionText)}>{this.props.getLabel('journey_card_session_in_progress_label')}</div>
				<div styleName="active-websession-join-button-cnt" className={css(myStyles.upcomingSessionDetails)} onClick={() => this.openWebSession(webSessionDetails.session)}>
					{this.renderJoinSessionButton(webSessionDetails.session, myStyles)}
				</div>
			</div>;
		}

		if(webSessionDetails.hasUpcomingSessionToday){
			const sessionTime = getFormattedSessionDate(webSessionDetails.session.get('startDate'), "short");
			
			return <div styleName="websession-details-cnt">
				<div styleName="websession-bell-icon-cnt"><SessionBellIcon/></div>
				<div styleName="websession-time-cnt" className={css(myStyles.upcomingSessionDetails)}>Session: {sessionTime}</div>
			</div>;
		}
		
		return null;
	}

	render() {
		const {
			batchDetails,
			skinGuide,
			isRequested,
			getLabel,
			userBatch,
			isActive,
			webSessionDetails,
			hideProgress,
			isBDOrg
		} = this.props;
		const myStyles = getSkin(skinGuide);
		const isBatchLockedBecauseOfPrereq = userBatch && userBatch.get('lock_details') && userBatch.get('lock_details').get('locked') ===  true && userBatch.get('lock_details').get('lock_reason') === "pre_requisite_not_done";

		let image_url = batchDetails.getIn(['journey', 'catalogImageUrl']);
		if (!checkIfPresent(image_url))
			image_url = "https://webassets.knolskape.com/misc/ajinkya_wankhade/2019/11/25/52/videoimage.png";

		const domainKey = batchDetails.getIn(['journey', 'domain', 'key']);

		const simColourCode = batchDetails.getIn(["journey", "settings", "simColourCode"]);
		const showSimColorCode = isBDOrg && !!simColourCode
		const stripColors = Object.freeze({
			macroSim: "#9340BA",
			microSim: "#F7AF44",
			nanoSim: "#4FCC54",
			nonSim: "#FFFFFF",
		});

		const bundleType = batchDetails.getIn(["journey", "settings", "bundleType"]);
		const showBundleType = (bundleType && bundleType !== "false") ? true : false;

		return (
			<div styleName="journey-card-cnt" className={this.state.isSessionActive ? css(myStyles.webSessionActive): null}>
			{showSimColorCode && <div styleName="strip1" style={{ backgroundColor: stripColors[simColourCode]}} />}
			{showSimColorCode && <div styleName="strip2" style={{ backgroundColor: stripColors[simColourCode]}} />}
				<LockTooltip
					tooltipText={isBatchLockedBecauseOfPrereq && userBatch.get('lock_details').get('lock_explanation')}
					style={{ top: 26, left: 85 }}
					skinGuide={this.props.skinGuide}
				>
				<div styleName="journey-details-cnt">
					<div styleName="image-name-cnt">
						<div styleName="image-cnt">
							<img src={image_url} alt={batchDetails.getIn(['journey', 'name'])}/>
							{isBatchLockedBecauseOfPrereq && <div className={css(myStyles.lockedDiv)} styleName="locked-div">
								<div styleName="locked-icon"><LockIcon /></div>
							</div> }
						</div>
						<div styleName="name">
							{/* <div className={css(myStyles.categoryName)}>
								{!isBDOrg && domainKey && getLabel(`domain_${domainKey}_label`)}
							</div> */}
							<div className={css(myStyles.journeyName)}>
								{batchDetails.getIn(['journey', 'name'])}
							</div>
						</div>
					</div>
					<div style={{ width: '100%', marginTop: '10px' }} >
						{
							isRequested ?
								<div className={css(myStyles.requestStatus)} styleName="request-status">
									{
										batchDetails.getIn(['status']) === 'REJECTED' ?
											getLabel('approval_rejected_label')
											:
											getLabel('pending_approval_label')
									}
								</div>
								:
								<>
									{
										webSessionDetails && webSessionDetails.session ? this.renderWebSessionDetails(webSessionDetails, myStyles): 	this.getDurationDetails(myStyles)
									}
									{isActive && !hideProgress && this.getProgressComponent(myStyles)}
								</>
						}
					</div>
				</div>
				<div styleName="journey-action-cnt">
					{this.getAction(myStyles, isBatchLockedBecauseOfPrereq)}
				</div>
				<div style={{ position: "absolute", display: 'flex', top: "6px", right: 0, gap: "5px"}}>
				{
					showBundleType &&
					<div className={css(myStyles[bundleType])} styleName="bundle-tag">
						{getLabel(`${bundleType}_bundle_label`)}
					</div>
				}
				{
					checkIfPresent(batchDetails.getIn(['journey', 'format', 'key'])) &&
					<div className={css(myStyles.formatName)} styleName="format-tag">
						{getLabel(`format_${batchDetails.getIn(['journey', 'format', 'key'])}_label`)}
					</div>
				}
				</div>
				</LockTooltip>
				<div style={{display: 'none'}}>{this.props.refreshTime}</div>
			</div>
		);
	}
}

export default JourneyCard;