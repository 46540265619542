import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setSelectedJourneyInstanceDetails } from 'actions/journeys/actionCreators';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchJourneyDetails extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('GET_JOURNEY_DETAILS').replace('JOURNEY_ID', apiInfoObject.journeyId)

	successCall = (response) => {
    console.log("corp journeyDetails ====> ", response)
		this.dispatch(setSelectedJourneyInstanceDetails(response));
		return response;
	}

	onError = () => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		sendUserToInvalidPage();
	}
}

export default new FetchJourneyDetails();